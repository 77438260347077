import React from "react";
import { ICustomMaterialMenuProps, ICustomMaterialMenuState, IPopoverItemData } from "./Interface";
import { Button, Popover, Paper, MenuList, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CurrencyExchangeSharpIcon from '@mui/icons-material/CurrencyExchangeSharp';

class CustomMaterialMenu extends React.Component<ICustomMaterialMenuProps, ICustomMaterialMenuState> {

    constructor(props_: ICustomMaterialMenuProps) {
        super(props_);

        this.state = {
            popOverItem: null,
            popOverTargetElement: null
        }
    }

    public render() {
        return (<>
            <Button color="secondary" disabled={this.props.disabled} style={{
                minWidth: "unset"
            }}
                onClick={(ev_) => {
                    // if (this.props.onMultiMoreClick) {
                    //     this.props.onMultiMoreClick(this.state.selectedItems)
                    // }
                    // else if (this.props.multiPopoverItems && this.props.multiPopoverItems.length) {
                    //     this.setState({
                    //         popOverTargetElement: ev_.target as HTMLElement
                    //     });
                    // }
                    this.setState({
                        popOverTargetElement: ev_.target as HTMLElement
                    });
                }}
            >
                <MoreVertIcon fontSize={this.props.fontSize} />
            </Button >
            {
                this.props.multiPopoverItems != null && <Popover
                    open={this.state.popOverItem == null && this.state.popOverTargetElement != null}
                    anchorEl={this.state.popOverTargetElement}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    onClose={() => {
                        this.setState({
                            popOverTargetElement: null,
                            popOverItem: null
                        });

                        if (this.props.onClose) {
                            this.props.onClose(false);
                        }
                    }}
                >
                    {this._buildPopoverItems(this.props.multiPopoverItems)}
                </Popover>
            }

            {/* <IconButton aria-label="More" disabled={false}
                onClick={(ev_) => {
                    // if (this.props.onMultiMoreClick) {
                    //     this.props.onMultiMoreClick(this.state.selectedItems)
                    // }
                    // else if (this.props.multiPopoverItems && this.props.multiPopoverItems.length) {
                    //     this.setState({
                    //         popOverTargetElement: ev_.target as HTMLElement
                    //     });
                    // }
                }}>
                <MoreVertIcon fontSize={this.props.fontSize} />
            </IconButton> */}
        </>)
    }

    private _buildPopoverItems = (popoverData_: IPopoverItemData[]) => {
        return (
            <Paper style={{ minWidth: "100px" }}>
                <MenuList>
                    {
                        popoverData_.map((d, index) => {
                            return <MenuItem
                                disabled={d.disabled}
                                key={index}
                                className={"w-100 multiPopoverButton " + (d.textStyle ? d.textStyle : "")}
                                onClick={() => {
                                    this.setState({
                                        popOverTargetElement: null,
                                        popOverItem: null
                                    });

                                    if (this.props.onPopoverClick) {
                                        this.props.onPopoverClick(d.key, d.label);
                                    }
                                }}
                            >{d.key == "refund" && <CurrencyExchangeSharpIcon fontSize="small" style={{marginRight: '5px'}} />} {d.label == 'Active' || d.label == 'Inactive' ? <div className={'list-status dropdown-status ' + d.key}></div> : null} {d.label}</MenuItem>
                        })
                    }
                </MenuList>

            </Paper>
        );
    }
}

export default CustomMaterialMenu;