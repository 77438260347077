import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import TextField from '@mui/material/TextField';
import { amountFormat } from '../../../Component/helper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import './SettingDialogPopop.css';
import InputAdornment from '@mui/material/InputAdornment';
import FeeService from '../../../Services/FeeService';


interface SettingDialogPopopProps {
    open: boolean;
    onClose?: (state: boolean, action: string) => void;
    accessToken: string;
}
interface SettingDialogPopopState {
    isMarkupActive: boolean;
    markupPercentage: number;
    platformFeePercentage: number;
    isPlatformPercentActive: boolean;
    platformFee: string;
    isPlatformFeeActive: boolean;
    inputType: string;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class SettingDialogPopop extends React.Component<SettingDialogPopopProps, SettingDialogPopopState> {
    constructor(props_: SettingDialogPopopProps) {
        super(props_);

        this.state = {
            isMarkupActive: true,
            markupPercentage: 0,
            platformFeePercentage: 0,
            isPlatformPercentActive: true,
            platformFee: '0',
            isPlatformFeeActive: true,
            inputType: 'text'
        }
    }

    componentDidMount(): void {
        this.getPlatFeeSetting();
    }

    public render() {
        return (
            <Dialog
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    this.handleClose('cancel')
                }}
                fullWidth={true}
                aria-describedby="alert-dialog-slide-description"
                className='setting-popup'
            >
                <DialogTitle>{"Platform fee setting"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Markup Percentage
                    </DialogContentText>
                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        {/* <FormLabel component="legend">Assign responsibility</FormLabel> */}
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={this.state.isMarkupActive} onChange={() => {
                                        this.setState({
                                            isMarkupActive: !this.state.isMarkupActive
                                        })
                                    }} color='secondary' name="isMarkupActive" />
                                }
                                label="Markup Percentage"
                            />
                            <TextField
                                variant="outlined"
                                color="primary" // Use the primary color
                                // value={amountFormat(parseFloat(this.props.paidAmount.toString().replace(/,/g, '')), 'en-US', 'USD').replace("$", "")}
                                value={this.state.markupPercentage}
                                onChange={(event: any) => {
                                    this.setState({
                                        markupPercentage: event.target.value
                                    })
                                }}
                                type='number'
                                disabled={!this.state.isMarkupActive}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                                }}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={this.state.isPlatformPercentActive} onChange={() => {
                                        this.setState({
                                            isPlatformPercentActive: !this.state.isPlatformPercentActive
                                        })
                                    }} color='secondary' name="isPlatformPercentActive" />
                                }
                                label="Platform fee percentage"
                            />
                            <TextField
                                variant="outlined"
                                color="primary" // Use the primary color
                                // value={amountFormat(parseFloat(this.props.paidAmount.toString().replace(/,/g, '')), 'en-US', 'USD').replace("$", "")}
                                value={this.state.platformFeePercentage}
                                type='number'
                                disabled={!this.state.isPlatformPercentActive}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                onChange={(event: any) => {
                                    this.setState({
                                        platformFeePercentage: event.target.value
                                    })
                                }}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={this.state.isPlatformFeeActive} onChange={() => {
                                        this.setState({
                                            isPlatformFeeActive: !this.state.isPlatformFeeActive
                                        })
                                    }} color='secondary' name="isPlatformFeeActive" />
                                }
                                label="Platform fee"
                            />
                            <TextField
                                variant="outlined"
                                color="primary" // Use the primary color
                                // value={amountFormat(parseFloat(this.state.platformFee.toString().replace(/,/g, '')), 'en-US', 'USD').replace("$", "")}
                                value={this.state.platformFee}
                                disabled={!this.state.isPlatformFeeActive}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                onChange={(text) => {
                                    this.setState({ platformFee: text.target.value });
                                }}
                                onBlur={() => {
                                    let n_ = this.state.platformFee ? this.state.platformFee : '0';
                                    let udatedAmount = amountFormat(parseFloat(n_.replace(/,/g, '')), 'en-US', 'USD').replace("$", "");
                                    this.setState({ platformFee: udatedAmount, inputType: 'text' });
                                }}
                                onFocus={() => {
                                    let n_ = this.state.platformFee.replace(/,/g, '');
                                    this.setState({ platformFee: n_, inputType: 'number' });
                                }}
                                type={this.state.inputType}
                            />
                        </FormGroup>
                        {/* <FormHelperText>Be careful</FormHelperText> */}
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.handleClose('cancel');
                    }}>Cancel</Button>
                    <Button onClick={() => {
                        this.handleClose('ok');
                    }} color='secondary' variant="contained">Save</Button>
                </DialogActions>
            </Dialog>
        )
    }

    private handleChange = (event: any) => {

    }

    private handleClose = (action: string) => {
        if (action == "ok") {
            let postData = {
                "markupPercentage": this.state.markupPercentage,
                "isMarkupActive": this.state.isMarkupActive,
                "platformFeePercentage": this.state.platformFeePercentage,
                "isPlatformPercentActive": this.state.isPlatformPercentActive,
                "platformFee": this.state.platformFee,
                "isPlatformFeeActive": this.state.isPlatformFeeActive
            }

            FeeService.updatePlatformFee(this.props.accessToken, postData).then(tr => {
                if (tr.ok) {
                    if (this.props.onClose) {
                        this.props.onClose(false, action);
                    }
                }
                else {
                    localStorage.clear();
                    window.location.href = '/'
                }
            });
        }
        else if (this.props.onClose) {
            this.props.onClose(false, action);
        }

        // this.setState({
        //     open: false
        // })
        /*let amount_ = this.state.selectedAmount == "paidAmount" ? this.props.paidAmount : Number(this.state.amount.replace(/,/g, ''));

        if (this.state.selectedAmount)

            if (this.props.onClose) {
                this.props.onClose(false, amount_, action);
            }*/
    };

    private getPlatFeeSetting() {
        console.log(this.props.accessToken);
        FeeService.getPlatformFee(this.props.accessToken).then(tr => {
            if (tr.ok) {
                tr.json().then(resp => {
                    this.setState({
                        isMarkupActive: resp.isMarkupActive,
                        isPlatformFeeActive: resp.isPlatformFeeActive,
                        isPlatformPercentActive: resp.isPlatformPercentActive,
                        markupPercentage: resp.markupPercentage,
                        platformFee: Number(resp.platformFee).toFixed(2),
                        platformFeePercentage: resp.platformFeePercentage
                    })
                });
            }
        });
    }
}

export default SettingDialogPopop;